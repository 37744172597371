<template>
  <v-list-item class="rounded-lg">
    <v-list-item-icon>
      <v-icon size="60">mdi-note</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-subtitle>
        <span class="">You have been assigned Order</span>
        <a 
        class="text-decoration-underline"
         @click="notifRouterPass(notification.order.order_id )"
        > {{ notification.order.order_id }} - {{ notification.order.order_topic }}.
        </a> 
        <span class="ml-1">Make Sure you submit the order in time. </span>
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-card-actions>
      <span>{{ formatDateMonth(notification.created_at) }} </span>
    </v-card-actions>
  </v-list-item>
    
</template>

<script>
import generalMxn from '@/mixins/general_mixin';
import timezoneMxn from '@/mixins/timezone_mixin';
import colorMxn from '@/mixins/colorMxn';

export default {
  name:'OrderDraft',
  mixins: [colorMxn, timezoneMxn, generalMxn],
  props: ['notification'],
  data() {
    return {
      
    }
  }
}
</script>